<template>
	<div id="my">
		<div class="top">
			<div class="title">操作中心 <i></i> </div>
			<div class="infoCard">
				<div class="user">
					<div class="avatar">
						<img src="../../assets/imgs/yisheng.svg">
					</div>
					<div class="info">
						<div class="name">{{docInfo.fullName}}</div>
						<div class="stars">
							<img src="../../assets/imgs/star.png" v-for="i in 5" :key="i">
						</div>
						<div class="status">
							<p>
								<img src="../../assets/imgs/Layer.png">
								已完成平台备案
							</p>
						</div>
					</div>
				</div>
				<div class="nums">
					<div class="num">
						<h3>我的会议</h3>
						<p>{{nums.meetingNum}} 场</p>
					</div>
					<p class="len"></p>
					<div class="num">
						<h3>我的项目</h3>
						<p>{{nums.projectNum}} 个</p>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="myCont">
			<div class="user">
				<el-image :src="require('../../assets/imgs/tx.png')" fit="cover">
					<div slot="placeholder" class="image-slot">
						加载中<span class="dot">...</span>
					</div>
				</el-image>
				<div class="name" v-if="docInfo">{{docInfo.fullName}}</div>
				<div class="name" v-else>{{docInfo.phoneNumber}}</div>
			</div>
			<ul class="datas">
				<li>
					<img src="../../assets/imgs/docicon1.png" alt="">
					<div class="tit">我的会议</div>
					<div class="num">{{nums.meetingNum}}<b>/次</b> </div>
				</li>
				<li>
					<img src="../../assets/imgs/docicon2.png" alt="">
					<div class="tit">我的项目</div>
					<div class="num">{{nums.projectNum}}<b>/条</b> </div>
				</li>
			</ul>
		</div> -->
		<div class="opts">
			<div class="item" @click="toChangeInfo()">
				<span>
					<i class="el-icon-edit-outline"></i>
					个人信息修改
				</span>
				<b class="el-icon-arrow-right"></b>
			</div>
			<div class="item" @click="tomyproject()">
				<span>
					<i class="el-icon-notebook-1"></i>
					我参加的项目
				</span>
				<b class="el-icon-arrow-right"></b>
			</div>
			<div class="item" @click="changePwd()">
				<span>
					<i class="el-icon-setting"></i>
					修改密码
				</span>
				<b class="el-icon-arrow-right"></b>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getDoctorInfo,
		getMyProjectNumAndMeetingNum
	} from '@/request/api.js';
	export default {
		name: 'my',
		components: {},
		data() {
			return {
				docInfo: '',
				nums: ''
			};
		},
		created() {
			this.getDocInfo();
		},
		mounted() {},
		methods: {
			changePwd() {
				this.$router.push({
					name: 'changepwd',
					query: {
						status: 'd'
					}
				});
			},
			tomyproject() {
				this.$router.push({
					name: 'myproject'
				});
			},
			toChangeInfo() {
				this.$router.push({
					name: 'changeInfo'
				});
			},
			getDocInfo() {
				getDoctorInfo().then(res => {
					if (res.code == 200) {
						this.docInfo = res.data;
					};
				});
				getMyProjectNumAndMeetingNum().then(res => {
					if (res.code == 200) {
						this.nums = res.data;
					};
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	#my {
		padding: 20px;

		.top {
			padding: 20px;
			border-radius: 20px;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
			margin-bottom: 20px;

			.title {
				font-size: 20px;
				padding: 0 20px 0 30px;
				position: relative;
				margin-bottom: 20px;

				i {
					top: 50%;
					left: 0px;
					width: 20px;
					height: 20px;
					position: absolute;
					border-radius: 50%;
					background-color: #3563E9;
					border: 5px solid #C2D0F8;
					transform: translateY(-50%);
				}
			}

			.infoCard {
				display: flex;
				flex-direction: column;

				.user {
					display: flex;
					align-items: center;
					width: 100%;
					padding-bottom: 30px;

					.avatar {
						background-color: #0065FF;
						width: 130px;
						height: 100px;
						padding: 16px;
						border-radius: 10px;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							height: 100%;
						}
					}

					.info {
						height: 100px;
						flex: 1;
						padding-left: 40px;
						display: flex;
						flex-direction: column;
						justify-content: space-around;

						.name {
							font-size: 20px;
							color: #333;
						}

						.stars {
							display: flex;

							img {
								height: 14px;
								margin-right: 2px;
							}
						}

						.status {
							display: flex;
							align-items: center;
							font-size: 14px;
							color: #216ECC;

							img {
								height: 16px;
								margin-right: 0px;
							}
						}
					}
				}

				.nums {
					display: flex;

					.num {
						flex: 1;
						padding: 10px 20px;
						border-left: 6px solid #3563E9;

						h3 {
							font-size: 18px;
							margin-bottom: 10px;
						}

						p {
							font-size: 16px;
						}
					}
				}
			}
		}

		.opts {
			width: 100%;
			display: flex;
			flex-direction: column;

			.item {
				padding: 20px 30px;
				margin-bottom: 20px;
				border-radius: 10px;
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 16px;

				span {
					display: flex;
					align-items: center;
					margin-right: 5px;

					i {
						margin-right: 10px;
					}
				}

				i {
					font-size: 18px;
				}
			}
		}
	}
</style>